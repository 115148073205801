import React from 'react'
import { navigate } from 'gatsby'
import { titleCase } from '../helpers.js'

import list from '../css/modules/navList.module.scss'

class NavList extends React.Component {
  constructor() {
    super()
  }

  listSort() {
    var listItems = []
    var listData = this.props.data
    if (this.props.location === 'search') {
      let popular = listData.filter(
        item => 'Most Popular'.indexOf(item.node.title) > -1
      )
      listData = listData.filter(
        item =>
          [
            'Animals',
            'Te Ao Māori',
            'Friendship',
            'Whanau',
            'Fantastical',
            'Classics',
          ].indexOf(item.node.title) > -1
      )
      listData.unshift(popular[0])
    } else {
      listData.sort((a, b) => {
        if (a.node.type != 'reading_age') {
          return a.node.title < b.node.title
            ? -1
            : a.node.title > b.node.title
            ? 1
            : 0
        } else {
          return a.node.description - b.node.description
        }
      })
    }
    listItems = listData.map((item, i) => (
      <NavLink clicked={this.props.clicked} data={item} key={i}>
        {item.node.title}
      </NavLink>
    ))
    return listItems
  }

  render() {
    return (
      <ul
        className={
          this.props.location === 'search' ? list.searchDropdown : list.dropdown
        }>
        {this.props.location === 'search' ? (
          <li>
            <h3 className={list.header}>Top Categories</h3>
          </li>
        ) : null}
        {this.listSort()}
      </ul>
    )
  }
}

class NavLink extends React.Component {
  constructor() {
    super()
    this.clickHandler = this.clickHandler.bind(this)
  }

  clickHandler(e) {
    return navigate(this.props.data.node.gatsby_slug)
  }

  render() {
    return (
      <li>
        <a className={list.link} onMouseDown={e => this.clickHandler(e)}>
          <span>{this.props.children}</span>
        </a>
      </li>
    )
  }
}

export default NavList
