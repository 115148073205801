import React from 'react'
import NavList from '../components/navList.js'

export default class DropdownMenu extends React.Component {
  constructor() {
    super()
  }

  createNavLists(clicked) {
    let current = clicked
    if (clicked == 'Discover' || clicked == 'Search') {
      null
    } else {
      current = `${clicked.toLowerCase()}s`
      return (
        <NavList
          location="nav"
          styles={{
            visibility: this.props.activeLink == clicked ? 'block' : 'none',
          }}
          data={this.props[current]}
          clicked={current.slice(0, -1)}
        />
      )
    }
  }

  render() {
    return <div>{this.createNavLists(this.props.activeLink)}</div>
  }
}
