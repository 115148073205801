import React from 'react'
import SlideMenu from '../components/slideMenu.js'
import Banner from '../components/banner.js'
import DropdownMenu from '../components/dropdownMenu.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SubHeader extends React.Component {
  constructor(props) {
    super(props)
    this.handleActiveLink = this.handleActiveLink.bind(this)
    this.searchQuery = this.searchQuery.bind(this)
    this.clickOutside = this.clickOutside.bind(this)
  }

  handleActiveLink(link) {
    this.props.activeLink(link)
  }

  searchQuery(e) {
    this.props.searchQuery(e)
  }

  clickOutside() {
    if (this.props.activeLink !== 'Discover') {
      this.handleActiveLink('Discover')
    }
  }

  getListNodes(genre = true, author = true, age = true) {
    var genreList = []
    var authorList = []
    var ageList = []
    if (genre === true) {
      genreList = this.props.data.allGenreJson.edges
    }
    if (author === true) {
      authorList = this.props.data.allBookAuthorJson.edges
    }
    if (age === true) {
      ageList = this.props.data.allReadingAgeJson.edges
    }
    var lists = {
      authors: authorList,
      ages: ageList,
      topics: genreList,
    }
    return lists
  }

  render() {
    return (
      <div>
        <Banner />
        <SlideMenu
          {...this.getListNodes(true, false, false)}
          onActiveChange={this.handleActiveLink}
          activeLink={this.props.linkState}
          searchState={this.props.searchState}
          searchQuery={this.searchQuery}
          siteSearchIndex={this.props.data.siteSearchIndex}
        />
        <DropdownMenu
          {...this.getListNodes()}
          activeLink={this.props.linkState}
        />
      </div>
    )
  }
}

export default SubHeader
