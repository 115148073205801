import React from 'react'
import { Link } from 'gatsby'
import styles from '../css/modules/slideMenu.module.scss'
import NavList from '../components/navList.js'
import Search from '../components/search.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../css/swiper.min.css'
import SwiperComponent from '../components/swiperComponent.js'

const navLinks = ['Discover', 'Topic', 'Author', 'Age', 'Search']

export default class SlideMenu extends React.Component {
  constructor(props) {
    super(props)
    this.navState = this.navState.bind(this)
    this.setSearchFocus = this.setSearchFocus.bind(this)
    this.state = {
      searchFocus: 0,
    }
  }

  navState(navLink, e) {
    let currentNav = navLink
    if (this.props.activeLink == currentNav) {
      this.props.onActiveChange('Discover')
    } else {
      this.props.onActiveChange(currentNav)
    }
  }

  setSearchFocus(e) {
    this.props.onActiveChange('Discover')
  }

  render() {
    let navLinksList = []
    for (var i = 0; i < navLinks.length; i++) {
      navLinksList.push(
        <div
          key={i}
          onClick={this.navState.bind(this, navLinks[i])}
          className={
            this.props.activeLink == navLinks[i]
              ? [styles.activeLink, styles.link].join(' ')
              : styles.link
          }>
          <div className={styles.linkText}>
            {navLinks[i] != 'Search' ? (
              navLinks[i]
            ) : (
              <FontAwesomeIcon className={styles.search} icon="search" />
            )}
            {navLinks[i] == 'Discover' ? (
              ''
            ) : (
              <FontAwesomeIcon
                className={styles.chevron}
                icon={
                  this.props.activeLink == navLinks[i]
                    ? 'chevron-up'
                    : 'chevron-down'
                }
              />
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={`${styles.sliderNav}`}>
        <div className={`${styles.wrapper}`}>{navLinksList}</div>
        <div onClick={this.clickOutside} className={styles.searchWrapper}>
          <Search
            authors={this.props.authors}
            ages={this.props.ages}
            topics={this.props.topics}
            searchState={this.props.searchState}
            searchQuery={this.props.searchQuery}
            data={this.props.siteSearchIndex}
            searchDropdownOpen={this.props.activeLink}
            onSearchChange={this.setSearchFocus}
          />
        </div>
      </div>
    )
  }
}
