import React from 'react'
import { navigate } from 'gatsby'

import storytimeDesktop from '../assets/storytimeDesktop.jpg'
import storytimeMobile from '../assets/storytimeMobile.jpg'
import blueShape from '../assets/blueShape.jpg'
import orangeShape from '../assets/orangeShape.jpg'

import banner from '../css/modules/banner.module.scss'

export default class Banner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={banner.header}>
        <div className={banner.logoContainer}>
          <h1>Storytime</h1>
          <img
            alt=""
            onClick={() => navigate('/')}
            className={banner.desktopLogo}
            src={storytimeDesktop}
          />
          <img
            alt=""
            onClick={() => navigate('/')}
            className={banner.mobileLogo}
            src={storytimeMobile}
          />
        </div>
        <div className={banner.subtitle}>
          <p className={banner.subtext}>
            New Zealand audio books, stories and music for kids of all ages.
          </p>
        </div>
        <div className={banner.shapeWrapperOffset}>
          <div className={banner.shapeWrapper}>
            <img alt="" className={banner.blueShape} src={blueShape} />
            <img alt="" className={banner.orangeShape} src={orangeShape} />
          </div>
        </div>
      </div>
    )
  }
}
