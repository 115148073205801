import React from 'react'
import apple from '../assets/apple.jpg'
import google from '../assets/google.jpg'
import spotify from '../assets/spotify.jpg'
import BestOfStorytimeLogo from '../assets/podcastLogos/storytimePodcast.jpg'
import IcymiLogo from '../assets/podcastLogos/icymiPodcast.jpg'
import NanogirlLogo from '../assets/podcastLogos/nanogirlPodcast.jpg'

import callout from '../css/modules/callout.module.scss'

export default class CalloutContainer extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className={callout.calloutContainer}>
        {/* Best of Storytime Banner*/}
        <Callout
          title="Subscribe to the Best of Storytime podcast"
          logo={BestOfStorytimeLogo}
          bodyText="All the latest and greatest stories, delivered free straight to your
      podcast app."
          rnzUrl="https://www.rnz.co.nz/programmes/best-of-storytime-rnz"
          appleUrl="https://itunes.apple.com/NZ/podcast/id1490511732"
          spotifyUrl="https://open.spotify.com/show/6F7ejhCoeNFeOVZvD5zreq?si=g8Xtx5HYTQ2OGyWA9ZYa-w"
          googleUrl="https://podcasts.google.com/?feed=aHR0cHM6Ly93d3cucm56LmNvLm56L2FjYXN0L2Jlc3Qtb2Ytc3Rvcnl0aW1lLXJuei5yc3M"
          morePodsUrl="https://www.rnz.co.nz/programmes/best-of-storytime-rnz/podcast"
          bgColor="#D1ECF4"
        />
        {/* News 2 Me Banner*/}
        <Callout
          title="Subscribe to ICYMI"
          logo={IcymiLogo}
          bodyText="Get your news fix in five minutes with this weekly news podcast especially for kids."
          rnzUrl="https://www.rnz.co.nz/programmes/news-to-me"
          appleUrl="https://podcasts.apple.com/us/podcast/news-2-me/id1616937750"
          spotifyUrl="https://open.spotify.com/show/7GwLQJ6W5oUD3RQI9AUzzC"
          googleUrl="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cucm56LmNvLm56L2FjYXN0L25ld3MtdG8tbWUucnNz"
          morePodsUrl="https://www.rnz.co.nz/programmes/news-to-me/podcast"
          bgColor="#FBE9D9"
        />
        {/* Nanogirl Banner*/}
        <Callout
          title="Subscribe to Nanogirl's Great Science Adventures"
          logo={NanogirlLogo}
          bodyText="Answering your burning science questions and showing you the wonders of discovery."
          rnzUrl="https://www.rnz.co.nz/programmes/nanogirls-great-science-adventures"
          appleUrl="https://itunes.apple.com/nz/podcast/id1462120464"
          spotifyUrl="https://open.spotify.com/show/1RSLlXSmJeQ2I9LpvMYCa7"
          googleUrl="https://podcasts.google.com/?feed=aHR0cHM6Ly93d3cucm56LmNvLm56L2FjYXN0L25hbm9naXJscy1ncmVhdC1zY2llbmNlLWFkdmVudHVyZXMucnNz"
          morePodsUrl="https://www.rnz.co.nz/programmes/nanogirls-great-science-adventures/podcast"
          bgColor="#FAEED0"
        />
      </div>
    )
  }
}

class Callout extends React.Component {
  constructor() {
    super()
  }

  render() {
    const bodyTextMobile = (
      <div className={callout.bodyText}>
        <p>{this.props.bodyText}</p>
        <a href={this.props.rnzUrl}>
          <span className={callout.underline}>Find out more</span> →
        </a>
      </div>
    )

    const bodyTextDesktop = (
      <div className={callout.bodyText}>
        <p>
          {this.props.bodyText}
          <a href={this.props.rnzUrl}>
            <span className={callout.underline}>Find out more</span> →
          </a>
        </p>
      </div>
    )

    const logos = (
      <div className={callout.podcastWrapper}>
        <a href={this.props.appleUrl}>
          <img
            alt="Subscribe to Apple Podcasts"
            className={callout.podcast}
            src={apple}
          />
        </a>
        <a href={this.props.spotifyUrl}>
          <img
            alt="Subscribe to Spotify"
            className={callout.podcast}
            src={spotify}
          />
        </a>
        <a href={this.props.googleUrl}>
          <img
            alt="Subscribe to Google Podcasts"
            className={callout.podcast}
            src={google}
          />
        </a>
        <a href={this.props.morePodsUrl} className={callout.podcastLink}>
          <span className={callout.underline}>More ways to subscribe</span> →
        </a>
      </div>
    )

    return (
      <div
        className={callout.container}
        style={{ backgroundColor: `${this.props.bgColor}` }}>
        <div className={callout.wrapper}>
          <div className={callout.headerContainer}>
            <h2>{this.props.title}</h2>
            <div className={callout.desktopContent}>
              {bodyTextDesktop}
              {logos}
            </div>
          </div>
          <div className={callout.logoContainer}>
            <a href={this.props.rnzUrl}>
              <img
                alt="Best of Storytime podcast"
                className={callout.logo}
                src={this.props.logo}
              />
            </a>
            <div
              className={[callout.mobileContent, callout.mobileText].join(' ')}>
              {bodyTextMobile}
            </div>
            <div
              className={[callout.mobileContent, callout.mobileLogo].join(' ')}>
              {logos}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
