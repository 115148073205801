import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Index } from 'elasticlunr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavList from '../components/navList.js'
import reactReplace from 'react-string-replace'

import bar from '../css/modules/searchbar.module.scss'
import searchResults from '../css/modules/searchresults.module.scss'
import list from '../css/modules/autocomplete.module.scss'

const searchOptions = {
  bool: 'OR',
  expand: true,
}

// Search component
class Search extends Component {
  constructor(props) {
    super(props)
    this.searchInput = null
    this.handleFocus = this.handleFocus.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.searchQuery = this.searchQuery.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.closeInput = this.closeInput.bind(this)
    this.state = {
      prevQuery: ``,
      results: [],
      prevResults: [],
      focus: 0,
    }
  }

  placeholderText() {
    var placeholder = [
      `Search in Storytime`,
      'Search by Title',
      'Search by Title',
    ]
    return placeholder
  }

  inputFocus() {
    if (this.props.searchState != ``) {
      this.setState({ focus: 1 })
    } else {
      this.setState({ focus: 2 })
    }
  }

  inputBlur(e) {
    this.state.focus === 2 ? this.setState({ focus: 0 }) : null
  }

  inputChange(e) {
    var localthis = this
    var promise = new Promise(function(resolve, reject) {
      resolve(localthis.searchQuery(e))
    })
    promise.then(function() {
      if (localthis.props.searchState.length > 0) {
        localthis.setState({ focus: 1 })
      }
      if (localthis.props.searchState.length < 1) {
        localthis.setState({ focus: 2 })
      }
    })
  }

  handleChange(e) {
    this.inputChange(e)
    this.search(e)
  }

  handleFocus(e) {
    this.props.onSearchChange(e)
  }

  searchQuery(e) {
    this.props.searchQuery(e)
  }

  clearInput(e) {
    this.searchQuery('')
    this.setState({ focus: 2, results: [] })
  }

  closeInput(e) {
    this.searchQuery('')
    this.setState({ focus: 0, results: [] })
    this.handleFocus()
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.data.index)

  search(e) {
    const query = e
    this.index = this.getOrCreateIndex()
    this.setState({
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, searchOptions)
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }

  componentDidMount() {
    if (this.props.searchState) {
      this.handleChange(this.props.searchState)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState) {
      if (this.state.results.length === 0 && prevState.results.length > 0) {
        this.setState({
          prevResults: prevState.results,
          prevQuery: prevProps.searchState,
        })
      } else if (
        this.state.results.length > 0 &&
        prevState.results.length === 0
      ) {
        this.setState({ prevResults: [], prevQuery: '' })
      }

      if (
        prevProps.searchDropdownOpen !== 'Search' &&
        this.props.searchDropdownOpen == 'Search'
      ) {
        this.inputFocus()
        this.searchInput.focus()
      }
    }
  }

  componentWillUnmount() {
    if (this.state.results.length === 0 && this.state.prevResults.length > 0) {
      this.handleChange(this.state.prevQuery)
    }
  }

  render() {
    return (
      <div>
        <div
          className={bar.dropdownWrapper}
          style={{
            display:
              this.props.searchDropdownOpen == 'Search' ? 'block' : 'none',
          }}>
          <div className={bar.container}>
            <div className={bar.inner}>
              <div className={bar.border}>
                <label htmlFor="searchInput">Search</label>
                <FontAwesomeIcon
                  icon="search"
                  style={{ display: this.state.focus === 1 ? 'none' : 'unset' }}
                  className={
                    this.state.focus === 0
                      ? [bar.icon, bar.iconCenter].join(' ')
                      : bar.icon
                  }
                />
                <input
                  id="searchInput"
                  ref={elem => (this.searchInput = elem)}
                  type="text"
                  value={this.props.searchState ? this.props.searchState : ''}
                  placeholder={this.placeholderText()[this.state.focus]}
                  onChange={e => this.handleChange(e.target.value)}
                  onFocus={() => this.inputFocus()}
                  className={
                    this.state.focus === 0
                      ? bar.input
                      : this.state.focus === 1
                      ? [bar.input, bar.alignLefter].join(' ')
                      : [bar.input, bar.alignLeft].join(' ')
                  }
                />
                {this.state.focus == 1 ? (
                  <FontAwesomeIcon
                    icon="times-circle"
                    className={bar.close}
                    onClick={this.clearInput}
                  />
                ) : null}
                <span
                  className={bar.cancel}
                  style={{
                    display: this.state.focus != 0 ? 'block' : 'none',
                  }}
                  onClick={this.closeInput}>
                  Cancel
                </span>
              </div>
            </div>
          </div>
          <div className={list.autocomplete}>
            {this.state.focus === 2 ? (
              <NavList
                location="search"
                data={this.props.topics}
                clicked="Topic"
              />
            ) : null}
            {(this.state.results.length > 0 ||
              this.state.prevResults.length > 0 ||
              this.props.searchState.length > 0) &&
            this.state.focus === 1 ? (
              <AutoComplete
                query={this.props.searchState}
                prevQuery={this.state.prevQuery}
                results={this.state.results}
                prevResults={this.state.prevResults}
              />
            ) : (
              ''
            )}
            <SearchResults
              results={this.state.results}
              prevResults={this.state.prevResults}
              isOpen={this.props.searchState.length > 0 ? true : false}
            />
          </div>
        </div>
      </div>
    )
  }
}

class AutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.headerText = this.headerText.bind(this)
  }

  showAutoComplete() {
    let results =
      this.props.results.length == 0
        ? this.props.prevResults.length > 0
          ? this.props.prevResults
          : this.props.results
        : this.props.results
    let resultList = []
    let resultsNum = 6
    let resultsCount =
      results.length <= resultsNum ? results.length : resultsNum
    for (var i = 0; i < resultsCount; i++) {
      let title = results[i].title
      let result = reactReplace(title, this.props.query, (match, j) => (
        <strong title={title} key={j}>
          {match}
        </strong>
      ))
      result = (
        <Link to={'/' + results[i].gatsby_slug} key={i}>
          <p>{result}</p>
        </Link>
      )
      resultList.push(result)
    }
    return resultList
  }

  headerText() {
    return this.props.results.length === 0
      ? `Previous Search Results - ${this.props.prevQuery}`
      : 'Matching titles'
  }

  render() {
    return (
      <ul className={list.dropdown} style={{ zIndex: '2' }}>
        {this.props.results.length === 0 ? (
          <li>
            <h3 className={list.header}>NO RESULTS FOUND</h3>
            <span className={list.errorMessage}>
              We didn't find any matches for
              <strong>'{this.props.query}'</strong>. Try a broader search, or
              double check the keywords you entered
            </span>
          </li>
        ) : (
          ''
        )}
        {this.props.results.length > 0 || this.props.prevResults.length > 0 ? (
          <li>
            <h3 className={list.resultsHeader}>{this.headerText()}</h3>
          </li>
        ) : (
          ''
        )}
        {this.showAutoComplete()}
      </ul>
    )
  }
}

class SearchResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closeMenu: false,
    }
  }

  setDisplay() {
    return this.props.isOpen ? 'block' : 'none'
  }

  render() {
    let results =
      this.props.results.length == 0
        ? this.props.prevResults.length > 0
          ? this.props.prevResults
          : this.props.results
        : this.props.results
    var resultsNum = results.length > 0 ? results.length : 'no'
    return (
      <div
        className={searchResults.wrapper}
        style={{
          display: this.setDisplay(),
        }}>
        <div className={searchResults.container}>
          {results.slice(0, 6).map(
            (book, index) => (
              <div key={index} className={searchResults.item}>
                <div className={searchResults.background}>
                  {book.image ? (
                    <img
                      alt=""
                      src={book.image}
                      className={searchResults.blurbg}
                    />
                  ) : null}
                  <Link to={book.gatsby_slug}>
                    {book.image ? (
                      <img
                        alt={`Cover of ${book.title}`}
                        className={searchResults.image}
                        src={book.image}
                      />
                    ) : (
                      <p className={searchResults.text}>{book.title}</p>
                    )}
                  </Link>
                </div>
              </div>
            ),
            this
          )}
        </div>
      </div>
    )
  }
}

export default Search
