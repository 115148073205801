import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import SubHeader from '../components/subheader.js'
import Footer from '../components/footer.js'
import CollectionBuilder from '../components/categoryWrapper.js'
import Featured from '../components/featured.js'
import CalloutContainer from '../components/callout.js'

import slider from '../css/modules/slideMenu.module.scss'
import header from '../css/modules/categoryWrapper.module.scss'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      activeLink: 'Discover',
    }
    this.activeLink = this.activeLink.bind(this)
    this.searchQuery = this.searchQuery.bind(this)
    this.clickOutside = this.clickOutside.bind(this)
  }

  books() {
    return this.props.data.allBookJson.edges
  }

  activeLink(e) {
    this.setState({ activeLink: e })
  }

  searchQuery(e) {
    this.props.searchQuery(e)
  }

  clickOutside() {
    if (this.state.activeLink !== 'Discover') {
      this.setState({ activeLink: 'Discover' })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    })
  }

  featuredBooks() {
    let books = this.books()
    let featuredArray = []
    for (var i = 0; i < books.length; i++) {
      for (var j = 0; j < books[i].node.genre.length; j++) {
        if (books[i].node.genre[j].slug === 'popular') {
          featuredArray.push(books[i].node)
        }
      }
    }
    // featuredArray = featuredArray.sort(function() {
    //   return 0.5 - Math.random()
    // })

    // Randomization not working; just order by date
    featuredArray = featuredArray.sort(function() {
      return 0.5 - Math.random()
    })
    return featuredArray
  }

  render() {
    return (
      <div>
        <div
          style={{
            opacity: this.state.loading ? '0' : '1',
            transition: 'opacity 0.1s',
          }}>
          <div onClick={this.clickOutside}></div>
          <SubHeader
            linkState={this.state.activeLink}
            activeLink={this.activeLink}
            searchState={this.props.searchState}
            searchQuery={this.searchQuery}
            data={this.props.data}
          />
          <div
            style={{
              opacity: this.state.activeLink === 'Discover' ? '1' : '0.3',
            }}
            onClick={this.clickOutside}>
            <Featured data={this.featuredBooks()} />
            <div className={header.bigHeader}>
              <h2 className={header.headerLeft}>Recently Added</h2>
            </div>
            <CollectionBuilder
              bookpage={false}
              wrappers={[
                {
                  type: 'date',
                },
              ]}
              data={this.props.data}
            />
            <CalloutContainer />
            <div className={header.bigHeader}>
              <h2 className={header.headerLeft}>Featured Topics</h2>
            </div>
            <CollectionBuilder
              bookpage={false}
              wrappers={[
                {
                  property: 'Animals',
                  type: 'genre',
                  slug: 'topic/animals',
                },
                {
                  property: 'Te Ao Māori',
                  type: 'genre',
                  slug: 'topic/te-ao-maori',
                },
                {
                  property: 'Friendship',
                  type: 'genre',
                  slug: 'topic/friendship',
                },
                {
                  property: 'Whanau',
                  type: 'genre',
                  slug: 'topic/whanau',
                },
                {
                  property: 'Fantastical',
                  type: 'genre',
                  slug: 'topic/fantastical',
                },
                {
                  property: 'Classics',
                  type: 'genre',
                  slug: 'topic/classics',
                },
              ]}
              data={this.props.data}
            />
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    siteSearchIndex {
      index
    }
    allGenreJson {
      edges {
        node {
          title
          slug
          gatsby_slug
          id
          type
        }
      }
    }
    allBookAuthorJson {
      edges {
        node {
          title
          slug
          gatsby_slug
          id
          type
        }
      }
    }
    allReadingAgeJson {
      edges {
        node {
          title
          slug
          gatsby_slug
          id
          type
          description
        }
      }
    }
    allBookJson {
      edges {
        node {
          id
          slug
          gatsby_slug
          date
          type
          title
          image
          square_image
          genre {
            title
            id
            slug
          }
          narrator {
            title
            id
          }
          reading_age {
            title
            id
          }
          book_author {
            title
            id
          }
          illustrator
          book_synopsis
          publisher {
            title
            id
          }
          clip {
            title
            id
          }
          reading_age {
            title
            id
          }
        }
      }
    }
  }
`
