import React from 'react'
import { Link } from 'gatsby'
import featured from '../css/modules/featured.module.scss'

import '../css/swiper.min.css'
import SwiperComponent from '../components/swiperComponent.js'

export default class Featured extends React.Component {
  constructor() {
    super()
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.data.length === this.props.data.length ? false : true
  }

  render() {
    let featuredBooks = this.props.data.map((book, i) => (
      <FeaturedCircle book={book} key={i} />
    ))
    return (
      <div>
        <div className={featured.header}>
          <h2 className={featured.headerleft}>Most Popular</h2>
        </div>
        <SwiperComponent
          navigation={2}
          params={{
            sliderTitle: 'Popular',
            breakpoints: {
              450: {
                slidesPerView: 2.3,
                spaceBetween: 16,
                // slidesOffsetBefore: 7,
                // slidesOffsetAfter: 7,
              },
              700: {
                slidesPerView: 3.3,
                spaceBetween: 16,
                // slidesOffsetBefore: 7,
                // slidesOffsetAfter: 7,
              },
              1024: {
                slidesPerView: 4.7,
                spaceBetween: 16,
                // slidesOffsetBefore: 25,
                // slidesOffsetAfter: 25,
              },
              99999: {
                slidesPerView: 6,
                spaceBetween: 16,
              },
            },
          }}>
          {featuredBooks}
        </SwiperComponent>
      </div>
    )
  }
}

class FeaturedCircle extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className={`${featured.circle} swiper-slide`}>
        <Link to={'/' + this.props.book.gatsby_slug}>
          <div className={featured.imageContainer}>
            <img
              alt={`Cover of ${this.props.book.title}`}
              src={this.props.book.square_image || this.props.book.image}
            />
          </div>
        </Link>
      </div>
    )
  }
}
